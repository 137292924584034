<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="octTomografiaDeCoerenciaOptica"
    :hasExam="hasAttendanceValues('exam')"
    @getData="simpleImportData(null, $event)"
  >
    <b-row>
      <b-col>
        <b-row>
          <b-col>
            <div class="custom-input-group" >
              <div class="custom-input">
                <div class="eye-area" >
                  <EyeFill /> D
                </div>
                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br">
                    <span>Esp.central foveal</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoDireito"
                        :disabled="!canEdit"
                        @input="el => onInput('olhoDireito', el.target.value)"
                      />
                    </div>
                  </div>
                </div>

                <div class="custom-input flex-2 eye-box-container">
                  <div class="text-area type-2 with-br">
                    <span>Observações</span>
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.observacaoDireito"
                        :disabled="!canEdit"
                        @input="el => onInput('observacaoDireito', el.target.value)"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="custom-input no-bb">
                  <div class="eye-area">
                  <EyeFill /> E
                  </div>

                <div class="custom-input eye-box-container">
                  <div class="text-area type-2 with-br">
                    <div>
                      <input
                        autocomplete="off"
                        type="text"
                        class="form-control text-center"
                        :value="form.fields.olhoEsquerdo"
                        :disabled="!canEdit"
                        @input="el => onInput('olhoEsquerdo', el.target.value)"
                      />
                    </div>
                  </div>
                </div>

                <div class="custom-input flex-2 eye-box-container">
                  <div class="text-area type-2 with-br">
                    <input
                      autocomplete="off"
                      type="text"
                      class="form-control text-center"
                      :value="form.fields.observacaoEsquerdo"
                      :disabled="!canEdit"
                      @input="el => onInput('observacaoEsquerdo', el.target.value)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </b-col>
          <button
            class="btn blue-underline button-copy"
            @click="copyEyeValues"
            v-if="canEdit"
            v-b-tooltip.hover title="Copiar valores O.D para O.E"
          >
            <Copy />
          </button>
        </b-row>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { debounce } from 'lodash'
import attendanceDataMixin from '@/mixins/Attendance/attendanceDataMixin'

export default {
  components: { 
    FormWrapper: () => import('@/components/Attendance/Forms/FormWrapper'), 
    EyeFill: () => import('@/assets/icons/eye-fill.svg'), 
    Copy: () => import('@/assets/icons/copy.svg') 
  },
   mounted() {
    this.debounceUpdateMR = debounce(this.updateMedicalRecord, 500, { leading: true })
  },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.octTomografiaDeCoerenciaOptica,
      medicalRecordsStore: state => state.attendance.medicalRecordsStore
    }),
    ...mapGetters('attendance', ['exam'])
  },
  mixins: [attendanceDataMixin('medicalRecordsStore')],
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/octTomografiaDeCoerenciaOptica', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    copyEyeValues() {
      this.updateForm('olhoEsquerdo', this.form.fields.olhoDireito)
      this.updateForm('observacaoEsquerdo', this.form.fields.observacaoDireito)
      this.debounceUpdateMR(this.form)
    },
    onInput(key, value) {
      this.updateForm(key, value)
      this.debounceUpdateMR(this.form)
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.getAutoRefraction{

  &:hover {
    .order-body {
      display: block;
    }
  }
  .order-body {
    display: none;
    background-color: #fff;
    border: 1px solid var(--neutral-200);
    border-radius: 4px;
    padding: 5px 25px;
    position: absolute;
    z-index: 5;
    box-shadow: 10px 5px 5px var(--neutral-200);
  }
}
.eyeValueChange {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 !important;
  position: relative;
  margin-left: -30px !important;
  z-index: 5;
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 30px;

    button {
      height: 20px;
      width: 30px;
      color: var(--greys-60);
      background-color: #fff;
      position: relative !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border: 1.5px solid var(--blue-100) !important;

      p {
        font-weight: 700;
      }

    }
  }
}
.refraction {
  color: var(--blue-500);
  text-decoration: underline;
  margin-top: -10px;
  margin-bottom: 10px;
  font-weight: 700;
}
.disabled {
  color: var(--neutral-200);
}
.eye-box-container {
  .eyeValueChange {
    visibility: hidden;
  }
  &:hover {
    .eyeValueChange {
      visibility: visible;
    }
  }
}
.custom-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--neutral-300);
  border-radius: 8px;
  margin: 24px 0 16px 0;

  .custom-input {
    flex: 1;
    height: 38px;
    border: 8px;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid var(--neutral-300);
    justify-content: center;

    
    &.flex-2{
      flex: 2 !important;
    }

    .eye-area {
      width: 60px;
      background-color: var(--neutral-100);
      border-right: 1px solid var(--neutral-300);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 0 0 0;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;

      svg {
        width: 16px;
        height: 16px;
        fill: var(--type-active);
        margin-right: 4px;
      }
    }

    &.no-bb {
      border-bottom: 0;

      .eye-area {
        border-radius: 0 0 0 8px;
      }
    }

    .text-area {
      flex: 1;
      line-height: 55px;
      margin-left: 16px;
      font-weight: 400;
      font-size: 16px;
      color: var(--type-active);

      .form-control {
        border: 0 !important;
        border-radius: 0 !important;

        &.with-bbr {
          border-radius: 0 0 8px 0 !important;
        }

        &.with-btr {
          border-radius: 0 8px 0 0 !important;
        }
      }

      &.type-2 {
        position: relative;
        margin-left: 0;
        text-align: center;

        span {
          width: 100%;
          position: absolute;
          top: -19px;
          left: 0;
          font-weight: 700;
          font-size: 12px;
          line-height: 16px;
          color: var(--type-active);
        }
      }
      &.with-br {
        border-right: 1px solid var(--neutral-300) !important;
      }

      &.with-brtr {
        border-radius: 0 8px 0 0 !important;
      }

      &.with-brbr {
        border-radius: 0 0 8px 0 !important;
      }
    }
  }
}
.border-top-right {
  border-radius: 0 8px 0 0 !important;
}
.border-bottom-right {
  border-radius: 0 0 8px 0 !important;
}
</style>